import React, { useEffect, useRef, useState } from 'react';
import 'rc-slider/assets/index.css';
import { formatValue } from '../utils/envConfig';

const DropDownOffPlan = ( { label, name, isOpen, toggleDropdown, items, icon, isLarge, onSelectionChange, selectedItem, isMulti = false, containerClassName, isSearchable, searchplaceholder, spanClass,customInput,addCommaSeperatorForSearch } ) =>
{

    const dropdownContentRef = useRef();
    const moreFiltersRef = useRef();

    const dropdownRef = useRef();

    const [ isModalOpen, setIsModalOpen ] = useState( false );

    const [ minArea, setMinArea ] = useState( 20 );

    const [ maxArea, setMaxArea ] = useState( 900 );

    const [ localSelectedItem, setLocalSelectedItem ] = useState( isMulti ? ( Array.isArray( selectedItem ) ? [ ...selectedItem ] : [ selectedItem ] ) : selectedItem );

    const [ searchText, setSearchText ] = useState( '' );

    const [ filteredItems, setFilteredItems ] = useState( items );

    const [ value, setValue ] = useState( {} );

    const [ localValue, setLocalValue ] = useState( {} );

    const [ count, setCount ] = useState( 0 );

    useEffect( () =>
    {
        setFilteredItems( items );

    }, [ JSON.stringify( items ) ] );
    useEffect( () =>
    {
        setValue( selectedItem ?? {} );
        if ( isLarge )
        {
            let lv = {};
            Object.entries( selectedItem ?? {} ).forEach( ( [ key, value ] ) =>
            {
                if ( items.some( v => v.name === key ) )
                {
                    lv = { ...lv, [ key ]: value };
                }
            } );
            setLocalValue( lv );
        }
    }, [ selectedItem ] );

    useEffect( () =>
    {
        setLocalSelectedItem( isMulti ? ( Array.isArray( selectedItem ) ? [ ...selectedItem ] : [ selectedItem ] ) : selectedItem );

    }, [ selectedItem ] );

    useEffect( () =>
    {

        document.addEventListener( "mousedown", handleClickOutside );

        return () => document.removeEventListener( "mousedown", handleClickOutside );
    }, [] );
    const handleClickOutside = ( e ) =>
    {
        const { current: wrap } = dropdownContentRef;
        const { current: moreFilters } = moreFiltersRef;

        if ( ( wrap && !wrap.contains( e.target ) ) || ( moreFilters && !moreFilters.contains( e.target ) ) )
        {
            toggleDropdown( undefined, false, undefined );
        }
    };

    const handleSearchChange = ( event ) =>
    {
        let text = event.target.value;
        if(addCommaSeperatorForSearch){
            text = text?.toString()?.replace( /,/g, '' ) 
        }
        setSearchText( text );


        if ( text === '' )
        {
            setFilteredItems( items ); // Reset filtered items when search text is empty
        } else
        {
            const filtered = items.filter( ( item ) =>
                item?.label?.replace( /[^\w\s]/g, '' ).toLowerCase()?.includes( text?.toLowerCase() )
            );
            setFilteredItems( filtered );
        }

    };

    const handleClearInput = () =>
    {
        setSearchText( '' );
        setFilteredItems( items ); // Reset filtered items when input is cleared
    };

    useEffect(()=>{
        if(!searchText){
            setFilteredItems( items ); 
        }
    },[searchText])

    const handleButtonClick = () =>
    {
        if ( label === 'Filters' )
        {
            setIsModalOpen( !isModalOpen );
        } else
        {
            toggleDropdown( label, isOpen, 'toggle' );
        }
    };
    function removeItemOnce ( array, value )
    {
        let arr = Array.from( array );
        var index = arr.findIndex( e => value.value === e.value );
        if ( index > -1 )
        {
            arr.splice( index, 1 );
        }
        return arr;
    }


    const closeModal = () => setIsModalOpen( false );

    const handleIconClick = ( e ) =>
    {
        e.stopPropagation();
        toggleDropdown( label, !isOpen );
    };

    const handleCloseClick = () =>
    {
        toggleDropdown( label, false, 'set' );
    };

    const handleItemClick = ( item ) =>
    {
        console.log( item );
        const { name } = item;
        setLocalSelectedItem( isMulti ? [ item ] : item );
        let lValue = item;
        if ( item.convertToNumber )
        {
            lValue.value = Number( lValue );
        }
        setValue( { ...value, [ name ]: isMulti ? Array.isArray( value[ name ] ) ? [ lValue, ...value[ name ] ] : [ lValue, value[ name ] ] : lValue } );
        setLocalValue( { ...localValue, [ name ]: isMulti ? Array.isArray( value[ name ] ) ? [ lValue, ...value[ name ] ] : [ lValue, value[ name ] ] : lValue } );

        if ( onSelectionChange )
        {
            onSelectionChange( { [ name ]: isMulti ? Array.isArray( value[ name ] ) ? [ lValue, ...value[ name ] ] : [ lValue, value[ name ] ] : lValue }, label );
        }
        toggleDropdown( label, false, 'set' );
    };

    const handleCustomInput = ( event ) =>
        {   
            event.preventDefault();
            const text = searchText;
    
            setSearchText( text );
            if ( text === '' ){

            }else{
                const { name,label,title,convertToCurrency } = items[0];
                const lValue = {name,label,title,value:searchText}
                if ( convertToCurrency )
                {
                    lValue.label = `AED ${lValue.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                    lValue.value  = Number( lValue.value );
                }
                setSearchText()
                setLocalSelectedItem(lValue)
                setValue( { ...value, [ name ]: lValue } );
                setLocalValue( { ...localValue, [ name ]: lValue } );
                toggleDropdown( label, false, 'set' );
                if ( onSelectionChange )
                {
                    onSelectionChange( { [ name ]: lValue }, label );
                }
    
            }
           
        };

    const handleCheckBox = ( item, val ) =>
    {

        let has = value[ item.name ]?.some( e => e.value?.toString() === val?.toString() ) ?? false;
        if ( has )
        {
            let newVal = removeItemOnce( value[ item.name ], item );
            setValue( { ...value, [ item.name ]: newVal } );
            setLocalValue( { ...localValue, [ item.name ]: newVal } );
        } else
        {
            let newVal = isMulti ? { ...value, [ item.name ]: value[ item.name ] ? [ ...value[ item.name ], item ] : [ item ] } : { ...value, [ item.name ]: [ item ] };
            setValue( newVal );
            setLocalValue( isMulti ? { ...localValue, [ item.name ]: localValue[ item.name ] ? [ ...localValue[ item.name ], item ] : [ item ] } : { ...localValue, [ item.name ]: [ item ] } );
        }
    };

    const handleApplyFilter = () =>
    {
        toggleDropdown( label, false, 'set' );
        onSelectionChange( value, label );
    };

    useEffect( () =>
    {
        if ( !localValue )
        {
            return;
        }
        let lCount = 0;
        Object.entries( localValue ?? {} ).map( val =>
        {
            if ( Array.isArray( val[ 1 ] ) )
            {
                lCount = lCount + val[ 1 ].length;
            } else if ( val[ 1 ] )
            {
                lCount = lCount + 1;
            }
        } );
        setCount( lCount );
    }, [ localValue ] );

    const renderSearchInput = () => (
        isSearchable ? (
            <div className="relative mb-3">
                <form onSubmit={customInput?handleCustomInput:null}>
                <input
                    type="text"
                    value={addCommaSeperatorForSearch?formatValue( searchText): searchText}
                    onChange={ handleSearchChange }
                    placeholder={ searchplaceholder }
                    className="search-input-class pr-8 rounded-full border-[#ad997a] w-full"
                />
                </form>
                { searchText && (
                    <button
                        className="absolute inset-y-0 right-0 px-3 py-2"
                        onClick={ handleClearInput }
                    >
                        <i className="fas fa-times text-gray-500"></i>
                    </button>
                ) }
            </div>
        ) : null
    );

    const renderListItem = ( item, index ) =>
    {
        return (
            <li
                key={ index }
                className="text-navy font-medium pl-2 pr-4 cursor-pointer hover:text-[#99700D] hover:font-bold pb-4"
                onClick={ () => handleItemClick( item ) }
            >
                { item?.label ?? item }
            </li>
        );
    };

    const renderCheckboxItem = ( item, index ) => (
        <li key={ index } className="flex items-center mr-5 pl-2 mb-3">
            <input type="checkbox" id={ item?.value }
                name={ item.name }
                value={ item.value ?? item }
                checked={ value[ item.name ]?.some( ( e ) => e.value === item.value ) }
                onChange={ ( e ) => { handleCheckBox( item, e.target.value ); } }
                className='rounded-[0.2rem] cursor-pointer' />
            <label htmlFor={ item?.value } className="text-navy font-Lexend ml-2">{ item?.label }</label>
        </li>
    );

    const renderSlider = () => (
        <div className='mb-5 flex  px-2'>
            <div>
                <label htmlFor="minAreaInput" className='text-navy font-Lexend'>Min Area (sqft)</label>
                <input
                    type="number"
                    id="minAreaInput"
                    value={ minArea }
                    onChange={ ( e ) => setMinArea( parseInt( e.target.value, 10 ) || 0 ) }
                    min={ 20 }
                    max={ maxArea }
                    className='rounded-lg  '
                />
            </div>
            <div className='ml-10'>
                <label htmlFor="maxAreaInput" className='text-navy font-Lexend'>Max Area (sqft)</label>
                <input
                    type="number"
                    id="maxAreaInput"
                    value={ maxArea }
                    onChange={ ( e ) => setMaxArea( parseInt( e.target.value, 10 ) || 0 ) }
                    min={ minArea }
                    max={ 1000 }
                    className='rounded-lg '
                />
            </div>
        </div>
    );

    const renderSection = ( section, index ) =>
    {
        return (
            <div key={ index } className='my-3'>
                <h3 className="text-navy font-bold pl-2 pr-4 pb-2">{ section?.sectionName }</h3>
                { section.type === 'slider' ? (
                    renderSlider()
                ) : (
                    <ul className={ section.type === 'checkbox' ? 'flex flex-wrap' : '' }>
                        { section?.items?.map( ( item, subIndex ) =>
                            section?.type === 'checkbox' ? renderCheckboxItem( item, subIndex ) : renderListItem( item, subIndex )
                        ) }
                    </ul>
                ) }
            </div>
        );
    };

    const renderDropdownContent = () =>
    {
        const searchInput = renderSearchInput();

        if ( Array.isArray( items ) && items.length > 0 )
        {
            if ( !items[ 0 ].sectionName )
            {
                return (
                    <div ref={ dropdownContentRef }>
                        { searchInput }
                        <ul >
                            { filteredItems?.map( ( item, index ) => renderListItem( item, index ) ) }
                        </ul>
                    </div>
                );
            } else
            {
                return (
                    <div ref={ moreFiltersRef }>
                        { searchInput }
                        { items?.map( ( section, index ) => renderSection( section, index ) ) }
                        <div className="flex justify-center space-x-4 my-4">
                            <button
                                className="bg-[#a38c6e] text-white font-bold 4xs:px-10 hover:bg-[#fff] hover:text-[#a38c6e] hover:border-2 border-2 border-[#fff]  hover:border-[#a38c6e] 4xs:py-2 lg:px-14 lg:py-3 rounded-full"
                                onClick={ handleApplyFilter }
                            >
                                Apply Filters
                            </button>
                            <button
                                className="bg-[#fff] text-[#a38c6e]  font-bold 4xs:px-8 hover:bg-[#a38c6e] hover:text-[#fff] 4xs:py-2 lg:px-14 lg:py-3 border-2 border-[#a38c6e] rounded-full"
                                onClick={ handleCloseClick }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                );
            }
        }
        return null;
    };

    const dropdownStyle = isLarge
        ? "dropdown-content absolute bg-white right-1 rounded-10 p-3 shadow-lg mt-2 rounded-xl pt-6 4xs:w-[22rem] lg:max-w-[40rem] lg:w-[40rem]"
        : "dropdown-content absolute bg-white left-2 rounded-10 p-2 shadow-lg mt-2 rounded-xl pt-6 max-w-[18rem] w-[18rem] max-h-[30rem] overflow-y-scroll pr-2";

    const renderModal = () => (
        <div style={ { position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', zIndex: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' } }>
            <div style={ { background: '#FFF', padding: '20px', maxWidth: '1279px', width: '100%', height: '100%', overflow: 'auto' } }>
                <button onClick={ closeModal } style={ { marginLeft: 'calc(100% - 40px)', marginBottom: '10px', cursor: 'pointer' } } className='text-navy underline font-Lexend font-medium'>Close</button>
                { items.map( ( section, index ) => renderSection( section, index ) ) }
                <div style={ { marginTop: '20px', textAlign: 'center' } }>
                    <button onClick={ closeModal } style={ { padding: '10px 20px', cursor: 'pointer' } } className='bg-[#a38c6e] rounded-full font-Lexend font-bold text-white hover:bg-[#fff] border-2 border-white hover'>Apply Filters</button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="dropdown relative" ref={ dropdownRef }>
            <button
                type="button"
                className={ ` bg-white rounded-full hover:bg-[#a38c6e] text-navy hover:text-[#fff]  xl:px-3 2xl:px-6 
                py-3 lg:px-6 md:px-4 sm:px-5 3xs:px-4 font-Lexend  flex items-center justify-between ${ containerClassName }` }
                onClick={ handleButtonClick }
            >
                <span
                    className={ `font-Lexend font-medium ${ spanClass }` }
                    style={ {
                        fontWeight: 600,
                        letterSpacing: "0.03rem",
                        fontSize: "15px",
                    } }
                >
                    { value[ name ]
                        ? Array.isArray( value[ name ] )
                            ? value[ name ][ 0 ]?.label
                            : value[ name ]?.label
                        : localSelectedItem?.label ?? label }
                </span>
                { label === "More Filters" ? (
                    <div
                        className="ml-3 w-6 h-6 bg-[#a38c6e] border-[0.05rem] border-white text-white rounded-full flex items-center justify-center"
                        style={ { cursor: "pointer" } }
                    >
                        <span className="text-sm">{ count }</span>
                    </div>
                ) : isMulti &&
                    value[ name ] &&
                    value[ name ]?.filter( ( item ) => item )?.length > 1 ? (
                    <>
                        <span className="ml-1  font-bold">
                            +{ value[ name ]?.filter( ( item ) => item )?.length - 1 }
                        </span>
                        <i
                            className={ `fas fa-chevron-${ isOpen ? "up" : "down"
                                }` }
                            style={ { marginLeft: "8px", cursor: "pointer" } }
                            onClick={ handleIconClick }
                        />
                    </>
                ) : (
                    <i
                        className={ `fas fa-chevron-${ isOpen ? "up" : "down" }` }
                        style={ { marginLeft: "8px", cursor: "pointer" } }
                        onClick={ handleIconClick }
                    />
                ) }
            </button>
            { isOpen && (
                <div className={ dropdownStyle } style={ { zIndex: "50" } }>
                    { renderDropdownContent() }
                </div>
            ) }
            { isModalOpen && label === "Filters" && renderModal() }
        </div>
    );
};

export default DropDownOffPlan;
